import { Select, Option } from "informed";
import React from "react";
import { useCustomFieldApi } from "~/helpers/hooks/useCustomFieldApi";
import Button from "../Button";
import { Text } from "../Typography";
import styles from "./index.module.scss";

type Props = {
  itemCount: number;
  perPage: number;
  pageSelected: number;
  siblingsShown: number;
  onPerPageChange: (perPageCount: number) => void;
  onPageSelectedChange: (pageNumber: number) => void;
};

/**
 * Component for handling generic table pagination on admin dashboard pages (user lists, etc).
 */
const AdminPagination = ({
  itemCount,
  perPage,
  pageSelected,
  siblingsShown,
  onPerPageChange,
  onPageSelectedChange,
}: Props) => {
  const [lowerRange, setLowerRange] = React.useState<number>(0);
  const [higherRange, setHigherRange] = React.useState<number>(0);
  const [pageNumbers, setPageNumbers] = React.useState<number[]>([]);
  const perPageFieldName = "AdminPagination-PerPageField";
  const perPageFieldApi = useCustomFieldApi(perPageFieldName);

  const changePage = (pageNumber: number) => {
    if (pageSelected !== pageNumber) {
      onPageSelectedChange(pageNumber);
    }
  };

  React.useEffect(() => {
    setLowerRange((pageSelected - 1) * perPage + 1);

    setHigherRange(
      perPage * pageSelected > itemCount ? itemCount : perPage * pageSelected
    );

    const tempPageCount = Math.ceil(itemCount / perPage);
    const tempPageNumbers: number[] = [];
    tempPageNumbers.push(pageSelected);

    for (let i = 1; i !== siblingsShown; i++) {
      if (pageSelected - i <= 0) {
        break;
      }

      tempPageNumbers.unshift(pageSelected - i);
    }

    if (tempPageNumbers[0] > 1) {
      tempPageNumbers.unshift(0);
      tempPageNumbers.unshift(1);
    }

    for (let i = 1; i !== siblingsShown; i++) {
      if (pageSelected + i >= tempPageCount) {
        break;
      }

      tempPageNumbers.push(pageSelected + i);
    }

    const lastNumber = tempPageNumbers[tempPageNumbers.length - 1];

    if (lastNumber < tempPageCount) {
      if (lastNumber + 1 < tempPageCount) {
        tempPageNumbers.push(0);
      }

      tempPageNumbers.push(tempPageCount);
    }

    setPageNumbers(tempPageNumbers);
  }, [perPage, pageSelected, itemCount, siblingsShown]);

  return (
    <div className={styles.Content}>
      <div className={styles.PerPageSection}>
        <Text>ITEMS PER PAGE: </Text>

        <Select
          className={styles.PerPageSelect}
          field={perPageFieldName}
          onChange={(event) => {
            perPageFieldApi.setValue(event.target.value);
            onPerPageChange(Number(event.target.value));
          }}
          initialValue={perPage}
          value={perPage}
        >
          <>
            {/* eslint-disable-next-line no-magic-numbers*/}
            {[5, 10, 15, 20, 25].map((option) => {
              return (
                <Option key={`page-option-${option}`} value={option}>
                  {option}
                </Option>
              );
            })}
          </>
        </Select>

        <Text>
          {lowerRange} - {higherRange} of {itemCount} results
        </Text>
      </div>

      <div className={styles.PageButtonContainer}>
        {pageNumbers.map((number, index) => {
          return number === 0 ? (
            <Text className={styles.PageElipsis} key={index}>
              ...
            </Text>
          ) : (
            <Button
              disabled={pageSelected === number}
              className={styles.PageButton}
              text
              onClick={() => changePage(number)}
              key={index}
            >
              {number}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(AdminPagination);
