import React from "react";
import { Helmet } from "react-helmet-async";

const isProduction = process.env.NODE_ENV === "production";
const cacheMaxAge = process.env.REACT_APP_CACHE_MAX_AGE;

/**
 * Handles adding MetaData for document body.
 */
const AddMetas = ({ children }: { children: React.ReactNode }) => (
  <>
    <Helmet>
      {isProduction && cacheMaxAge ? (
        <meta
          httpEquiv="cache-control"
          content="public"
          max-age={cacheMaxAge}
        />
      ) : (
        <meta httpEquiv="cache-control" content="no-cache" />
      )}
    </Helmet>
    {children}
  </>
);

export default AddMetas;
