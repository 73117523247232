import React, {
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
  useState,
} from "react";
import { Route, useParams, useHistory } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import { useFormState, useFormApi } from "informed";
import { useHeaderContext } from "~/contexts/HeaderProvider";
import { useMotorContext } from "~/contexts/MotorProvider";
import styles from "../../App.module.scss";

import NotFound from "~/components/NotFound";
import GridWrapper from "~/components/GridWrapper";
import OnboardingLayout from "~/components/Layouts/OnboardingLayout";

import {
  calculateCurrentStepIndex,
  StepType,
  getNextPath,
  getPreviousPath,
  screenTransitionAnimation,
} from "~/helpers/flow";
import { useIsPaymentCheckout } from "~/helpers/useIsPaymentCheckout";
import { useHomeContext } from "~/contexts/HomeProvider";
import { useChecklistContext } from "~/contexts/ChecklistProvider";
import { NCD_HISTORY_CAP } from "~/helpers/constants";
import pushWithParams from "~/helpers/pushWithParams";
import { GONE_TOO_FAR } from "~/routes/index.constant";
import { useMountEffect } from "~/helpers/hooks/useMountEffect";

type PropsType = {
  steps: StepType[];
  children: React.ReactElement[];
};

/**
 * handles a full list of route endpoints and displays them in order when clicking
 * next on the previous page (or whichever action pushes to next URL).
 */
const MultiStepForm = ({ steps, children }: PropsType) => {
  const history = useHistory();

  const params: {
    countryCode: string | undefined;
    insuranceType: string | undefined;
    policyId: string | undefined;
    step: string | undefined;
  } = useParams();

  const {
    location: { pathname },
  } = useHistory();

  if (params.policyId !== undefined) {
    steps.map((_step: StepType) => {
      _step.path = _step.path.replace(":policyId", params.policyId ?? "");
    });
  }

  const { step } = useFormState();
  const currentStepIndex = calculateCurrentStepIndex(steps, pathname);
  const { setStep } = useFormApi();
  const stepEl = useRef<HTMLDivElement>(null);
  const headerCtx = useHeaderContext();
  const nextPath = getNextPath(steps, pathname);
  const previousPath = getPreviousPath(steps, pathname);
  const path = useRef("");
  const currentStepData = steps[currentStepIndex];
  const motorCtx = useMotorContext();
  const homeCtx = useHomeContext();
  const checklistCtx = useChecklistContext();
  const isPaymentCheckout = useIsPaymentCheckout();
  const [stepLoaded, setStepLoaded] = useState<boolean>();
  const [tooFar, setTooFar] = useState(false);

  const childSteps = React.Children.map(children, (child, i) => {
    const props = {
      ...child.props,
      nextPath,
      step: i,
    };
    return React.cloneElement(child, props);
  });

  const updateHeader = useCallback(
    (total: number, step: number, title: string) => {
      headerCtx.setState({
        totalOfSteps: total,
        currentStep: step,
        title,
      });
    },
    [headerCtx]
  );

  const beforeCoverageMotor = [
    "/BRB/motor/driving-experience",
    "/BRB/motor/types",
    "/BRB/motor/own-vehicle",
    "/BRB/motor/year",
    "/BRB/motor/makes",
    "/BRB/motor/models",
    "/BRB/motor/style",
    "/BRB/motor/left-right-hand",
    "/BRB/motor/engine-size",
    "/BRB/motor/engine-modifications",
    "/BRB/motor/value",
    "/BRB/motor/coverage-start",
    "/BRB/motor/vehicle-purpose",
    "/BRB/motor/drivers-details",
    "/BRB/motor/no-claims-length",
    "/BRB/motor/claims-years",
    "/BRB/motor/claim-history",
    "/BRB/motor/claim-info",
    "/BRB/motor/job-shift-work",
    "/BRB/motor/student",
    "/BRB/motor/association",
    "/BRB/motor/quote-phone",
    "/BRB/motor/email",
    "/BRB/motor/coverage-plans",
  ];

  const afterCoverageMotor = [
    "/BRB/motor/account",
    "/BRB/motor/your-name",
    "/BRB/motor/phone",
    "/BRB/motor/password",
    "/BRB/motor/verification",
  ];

  const beforeCoverageHome = [
    "/BRB/home/rent-or-own",
    "/BRB/home/building-type",
    "/BRB/home/occupancy-type",
    "/BRB/home/commercial-activity",
    "/BRB/home/construction-type",
    "/BRB/home/flood-risk",
    "/BRB/home/coastal-property",
    "/BRB/home/property-density",
    "/BRB/home/good-state",
    "/BRB/home/replacement-value",
    "/BRB/home/past-claims",
    "/BRB/home/contents-coverage",
    "/BRB/home/contents-items",
    "/BRB/home/additional-protection",
    "/BRB/home/all-risk-items",
    "/BRB/home/all-risk-type",
    "/BRB/home/contents-value",
    "/BRB/home/insurance-review",
    "/BRB/home/coverage-start",
    "/BRB/home/existing-motor-policy",
    "/BRB/home/email",
    "/BRB/home/coverage-plan",
  ];
  const afterCoverageHome = [
    "/BRB/home/account",
    "/BRB/home/your-name",
    "/BRB/home/phone",
    "/BRB/home/password",
    "/BRB/home/verification",
  ];

  useMountEffect(() => {
    if (path !== undefined) {
      if (beforeCoverageMotor.includes(currentStepData.path)) {
        const hasAge = motorCtx.policyInfo.data.age !== undefined;
        setTooFar(!hasAge);
        !hasAge
          ? pushWithParams(history, `${GONE_TOO_FAR}?message=no-age`)
          : null;
      } else if (afterCoverageMotor.includes(currentStepData.path)) {
        const hasPolicy = motorCtx.policyInfo.data.id !== undefined;
        setTooFar(!hasPolicy);

        !hasPolicy
          ? pushWithParams(history, `${GONE_TOO_FAR}?message=no-policy`)
          : null;
      } else if (beforeCoverageHome.includes(currentStepData.path)) {
        const hasOwner = homeCtx.isOwner !== undefined;
        setTooFar(!hasOwner);
        !hasOwner
          ? pushWithParams(history, `${GONE_TOO_FAR}?message=no-owner`)
          : null;
      } else if (afterCoverageHome.includes(currentStepData.path)) {
        const hasPolicy = homeCtx.policyId !== undefined;
        setTooFar(!hasPolicy);

        !hasPolicy
          ? pushWithParams(history, `${GONE_TOO_FAR}?message=no-policy`)
          : null;
      }
    }
  });

  const handlePrevious = () => {
    if (previousPath) {
      if (
        currentStepData.path === "/BRB/motor/left-right-hand" &&
        !motorCtx.vehicleInfo.data.isSportsCar
      ) {
        history.push("/BRB/motor/models");
      } else if (
        currentStepData.path === "/BRB/motor/documents" &&
        motorCtx.savingsInfo.data.occupation === "Student"
      ) {
        history.push("/BRB/motor/engine-and-chassis");
      } else if (
        currentStepData.path === "/BRB/motor/job-shift-work" &&
        (motorCtx.policyInfo.data.claimsHistory === ">2" ||
          (motorCtx.policyInfo.data.yearsWithoutClaims || 0) > NCD_HISTORY_CAP)
      ) {
        motorCtx.policyInfo.removeItem("yearsWithoutClaims");
        history.push("/BRB/motor/no-claims-length");
      } else if (
        currentStepData.path ===
          `/checklist/motor/${checklistCtx.policyId}/started` ||
        currentStepData.path ===
          `/checklist/home/${checklistCtx.policyId}/started`
      ) {
        //TODO use the policy type from the checklist CTX to check once it is added
        history.push("/portal");
      } else if (
        currentStepData.path === "/BRB/home/insurance-review" &&
        !homeCtx.contentsValue
      ) {
        history.push("/BRB/home/contents-coverage");
      } else if (
        currentStepData.path === "/BRB/home/contents-value" &&
        !homeCtx.allRisk
      ) {
        history.push("/BRB/home/additional-protection");
      } else if (
        currentStepData.path ===
          `/checklist/motor/${checklistCtx.policyId}/health-conditions` &&
        (!checklistCtx.motor.additionalDrivers ||
          checklistCtx.motor.additionalDrivers.length === 0)
      ) {
        history.push(`/checklist/motor/${checklistCtx.policyId}/started`);
      } else if (
        currentStepData.path ===
          `/checklist/motor/${checklistCtx.policyId}/previous-provider` &&
        checklistCtx.ignoreEmployerInfo
      ) {
        history.push(
          `/checklist/motor/${checklistCtx.policyId}/refused-insurance`
        );
      } else {
        history.push(previousPath);
      }
    } else {
      if (
        currentStepData.path === "/BRB/motor/quick-note" ||
        currentStepData.path === "/BRB/home/quick-note"
      ) {
        history.push("/BRB/insurance");
      } else {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    setStepLoaded(false);
    if (!params.step && history.location.pathname !== steps[0].path) {
      history.replace(steps[0].path);
    }
  }, [params, history, steps]);

  useEffect(() => {
    setStepLoaded(true);
  }, []);

  useEffect(() => {
    if (currentStepIndex >= 0 && currentStepIndex !== step) {
      setStep(currentStepIndex);
    }
  }, [step, pathname, setStep, steps, currentStepIndex]);

  useEffect(() => {
    if (!path || path.current !== pathname) {
      path.current = pathname;
      updateHeader(
        steps.length,
        currentStepIndex,
        (currentStepData && currentStepData.headerTitle) || ""
      );
    }
  }, [
    pathname,
    headerCtx,
    steps,
    currentStepData,
    currentStepIndex,
    updateHeader,
  ]);

  useLayoutEffect(() => {
    if (stepEl.current !== null) {
      stepEl.current.focus();
    }
  }, [step]);

  if (!steps[step]) {
    console.warn("Step undefined");
    return null;
  }
  return (
    <AnimatedSwitch
      {...screenTransitionAnimation}
      didLeave={() => setStepLoaded(true)}
      className={styles.SwitchWrapper}
    >
      {steps.map((item, index) => {
        return (
          <Route exact path={item.path} key={`${item.path}-${index}`}>
            <OnboardingLayout
              hideBackButton={item.hideBackButton}
              // if step is not fully loaded
              //pass in empty function for goBack button
              // this avoids app crashing if user clicks "go back" quickly
              onPrevClick={stepLoaded ? handlePrevious : () => {}}
            >
              {isPaymentCheckout
                ? !tooFar && childSteps[index]
                : !tooFar && <GridWrapper>{childSteps[index]}</GridWrapper>}
            </OnboardingLayout>
          </Route>
        );
      })}
      <Route path="*" component={NotFound} />
    </AnimatedSwitch>
  );
};
export default MultiStepForm;
