import React from "react";
import { Helmet } from "react-helmet-async";
import { useTrackPageVisits } from "~/helpers/useTrackPageVisits";

/**
 * Handles loading in ActiveCampaign tracking javascript.
 */
const ActiveCampaign = ({ children }: { children: React.ReactNode }) => {
  useTrackPageVisits();

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
              vgo('setAccount', '${process.env.REACT_APP_ACTIVE_CAMPAIGN_ACC}');
              vgo('setTrackByDefault', true);

              vgo('process');
              `}
        </script>
      </Helmet>
      {children}
    </>
  );
};

export default ActiveCampaign;
