import React, { ReactNode, useContext, useState } from "react";
type Props = {
  children?: ReactNode;
};

export type SupportData = {
  isOpen?: boolean;
};

type SupportContext = {
  updateSupport: (newValues: SupportData) => void;
} & SupportData;

const initialState: SupportContext = {
  isOpen: false,
  updateSupport: () => {
    throw new Error("Support context has not yet been initialized.");
  },
};

const SupportContext = React.createContext(initialState);
export const useSupportContext = () => useContext(SupportContext);

/**
 * Handles the state of the support links.
 */
export default function SupportProvider({ children }: Props) {
  const [state, setState] = useState(initialState);

  const handleNewState = (newState: SupportData) => {
    if (newState) setState({ ...state, ...newState });
  };

  return (
    <SupportContext.Provider
      value={{
        ...state,
        updateSupport: handleNewState,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
}
