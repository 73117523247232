import React from "react";
import classnames from "classnames";
import AlmiIconVertical from "~/components/Icon/icons/AlmiCombinedVertical";
import AlmiIconHorizontal from "~/components/Icon/icons/AlmiCombined";

import styles from "./index.module.scss";

/**
 * Displays a friendly loading layout of the portal before data has loaded.
 */
const GhostPortal = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <div className={styles.logoDesktop}>
          <AlmiIconVertical />
        </div>
        <div className={styles.logoMobile}>
          <AlmiIconHorizontal height={22} width={93} />
        </div>
      </div>
      <div
        className={classnames(styles.notification, styles.backgroundGradient)}
      />
      <div className={classnames(styles.claims, styles.backgroundGradient)} />
      <div className={styles.policies}>
        <div className={classnames(styles.policy, styles.backgroundGradient)} />
        <div className={classnames(styles.policy, styles.backgroundGradient)} />
        <div className={classnames(styles.policy, styles.backgroundGradient)} />
      </div>
      <div className={classnames(styles.callout, styles.backgroundGradient)} />
    </div>
  );
};

export default GhostPortal;
