import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import { Props } from "./index.types";

/**
 * Usually used to display a yes/no question modal to the client.
 */
export default function Dialogue({ text, buttonGroup, className }: Props) {
  return (
    <div className={classnames(className, styles.DialogueWrapper)}>
      <div className={styles.TextContent}>{text}</div>
      {buttonGroup && <div className={styles.ButtonGroup}>{buttonGroup}</div>}
    </div>
  );
}
