import React, { ReactNode, useContext, useState } from "react";
type Props = {
  children?: ReactNode;
};

export type AssistantData = {
  text?: React.ReactNode;
  isOpen?: boolean;
  showTextOnMobile?: boolean;
};

type AssistantContext = {
  updateAssistant: (newValues: AssistantData) => void;
} & AssistantData;

const initialState: AssistantContext = {
  text: <></>,
  isOpen: false,
  showTextOnMobile: true,
  updateAssistant: () => {
    throw new Error("Assistant context has not yet been initialized.");
  },
};

const AssistantContext = React.createContext(initialState);
export const useAssistantContext = () => useContext(AssistantContext);

/**
 * Handles the state of the Almi Assistant popup on the footer of the screen.
 */
export default function AssistantProvider({ children }: Props) {
  const [state, setState] = useState(initialState);

  const handleNewState = (newState: AssistantData) => {
    if (newState) setState({ ...state, ...newState });
  };

  return (
    <AssistantContext.Provider
      value={{
        ...state,
        updateAssistant: handleNewState,
      }}
    >
      {children}
    </AssistantContext.Provider>
  );
}
